<template>
    <v-card max-width="350px" class="ma-2 justify-center text-center">
        <v-card-title>予約 / RESERVE</v-card-title>
        <v-form class="" @submit.prevent="rsvp">
            
            <v-text-field  label="ご招待者 / Invited by" variant="outlined" v-model="$store.state.rsvp.invitedBy" readonly></v-text-field>
            <v-select label="日付 / Date" :items="dates" item-value="id" item-title="title" v-model="selectedDate"></v-select>
            <v-select label="時間 / Time" :items="getSlots" item-title="title" item-value="key"
                v-model="selectedSlot"></v-select>
                <div class="guest-checkbox">
                <v-checkbox label="ご同伴者様がいる場合はこちらをチェック(1名様まで) / Add a guest" v-model="plusone"></v-checkbox>
                </div>
            <v-btn :disabled="checkValid" color="primary" type="submit">予約 / RESERVE</v-btn>
        </v-form>
        <v-card-text>
            <div>
                ※チケットの変更・キャンセルはできかねますので予めご了承ください。
            </div>
            <div>
                Please be advised that ticket changes or cancellations are not possible.
            </div>
        </v-card-text>

    </v-card>
</template>


<script>
import { getDocs, collection, setDoc, doc } from "firebase/firestore";
import { db } from '@/plugins/firebase'

export default {
    name: 'ConfirmRsvp',
    props: {
        id: String,
        t: String,
        sid: String
    },
    components: {
    },
    data() {
        return {
            plusone: false,
            dates: [],
            slotTimes: {},
            invites: [],
            selectedEntity: '1111',
            selectedDate: '',
            selectedSlot: ''

        }
    },
    computed: {
        checkValid(){
            return this.selectedDate == '' || this.selectedSlot == ''
        },
        getSlots() {
            const nGuests = this.plusone ? 2 : 1
            if (this.selectedDate in this.slotTimes)
                return this.slotTimes[this.selectedDate].filter((slot) => slot.available >= nGuests)
            else
                return []
        }
    },
    methods: {
        async fetchSlots() {
            const docRef = collection(db, `slots/${this.sid.split('_')[0]}/sections/${this.sid}/slots`);
            const inviteDocs = await getDocs(docRef);
            // this.dates = {}
            const datesTmp = []
            inviteDocs.forEach((slot) => {
                this.slotTimes[slot.id] = []
                const data = slot.data()
                const keys = Array.from(Object.keys(data)).sort()
                // const nGuests = this.plusone ? 2 : 1
                keys.forEach((key) => {
                    // if (data[key] >= nGuests) {
                    this.slotTimes[slot.id].push({ id: key, title: key, available: data[key] })
                    // }
                })
                datesTmp.push({ id: slot.id, title: slot.id.split('_')[2] })
            })
            datesTmp.sort(function(a, b) { 
                a = new Date(a.title.replaceAll('-','/'));
                b = new Date(b.title.replaceAll('-','/'));
                return a - b;//a >b ? -1 : a < b ? 1 : 0;
                // return b.title.localeCompare(a.title);
                // return Date.parse(a.title.replaceAll('-','/')) - Date.parse(b.title.replaceAll('-','/'))
            })
            this.dates = datesTmp;
        },
        async rsvp() {
            // const docRef = doc(db, `slots/${this.sid.split('_')[0]}/events/${this.sid}/entities/${}`);
            const docRef = doc(db, `confirmed/${this.id}`)
            let dateInfo = this.selectedDate.split('_')
            let info = {
                [`day${dateInfo[1]}`]: this.selectedDate,
                [`time${dateInfo[1]}`]: this.selectedSlot,
                [`plusone${dateInfo[1]}`]: this.plusone
            }
            await setDoc(docRef, info, { merge: true })
            this.$store.commit('setRsvp', {})
            // this.$router.push(`/invitation?id=${this.id}&t=${this.t}&sid=${this.sid}`)
            this.$router.push(`/events?id=${this.id}&t=${this.t}`)
        }
    },
    mounted(){
        if(this.sid.startsWith('undefined'))
          this.$router.push(`/events?id=${this.id}&t=${this.t}`)
    },
    beforeMount() {
        this.fetchSlots()
    }
}
</script>

<style scoped>
.guest-checkbox {
  font-size: 13px;
} 
</style>