<template>
  <div class="invitation-container" :style="{ 'background-color': colour1 }">
    <div class="flip-card-inner" id="flip-card-inner" :style="{ 'background-color': colour1 }" @click="$emit('close')">
      <div class="flip-card-front" id="flip-card-front">
        <div class="logo">
          <img class="logo" src="@/assets/images/curation_fair_logo_black.png" />
        </div>
        <div class="header1 invitation-font" :style="{ 'background-color': colour2 }">
          <div>VIP Invitation</div>
        </div>
        <div class="header2 invitation-font">
          <div class="entry-style">ご予約時間 / Date</div>
          <!-- <div>Wednesday March 7th, 13:00</div> -->
          <div>{{ getDate }}</div>
          <div class="header3 entry-style">ご招待者 / Invited By</div>
          <div>{{ $store.state.rsvp.invitedBy }}</div>
          <div class="header3 entry-style invitation-font">
            <div>{{ info.plusone ? '同伴あり / ADMITS TWO' : '同伴なし / ADMITS ONE' }}</div>
          </div>
        </div>

        <a
          :class="`invitation-font name-font ${$store.state.rsvp.firstname.length + $store.state.rsvp.lastname.length > 20 ? 'large' : 'x-large'}`">{{
            `${$store.state.rsvp.firstname} ${$store.state.rsvp.lastname}` }}</a>
        <div class="qr-container" ref="qr_container">
          <canvas class="qrcode" id="canvas"></canvas>
        </div>
      </div>
      <div class="flip-card-back smaller" id="flip-card-back" :style="{ 'background-color': colour1 }">
        <h2>Conditions:</h2>
        <br>
        <ul>
          <li>本招待状にて複数回のご来場は可能ですが、予約時間以外のご入場に際し会場の混雑状況次第ではお待ち頂く場合がございます。予めご了承ください。</li>
          <li>会場にはエレベーターがございません。段差やフロアの移動は全て階段となります。</li>
          <li>館内では靴を脱ぎ、スリッパにお履きかえいただきます。</li>
          <li>また、手荷物を預かるロッカーのご用意はございませんので予めご了承ください。</li>
        </ul>
        <br>
        
        <ul>
          <li>Multiple visits are allowed with this invitation; however, please be aware that entry outside of the reserved
          time may require waiting depending on the venue's congestion.</li>
          <li>Please note there are no elevators at the venue, and all movement within the building involves stairs.</li>
          <li>Please remove your shoes inside the building and switch to slippers.</li>
          <li>Locker facilities for belongings are not available, so please be aware in advance.</li>
        </ul>
      </div>
    </div>
    <div class="conditions" text-align>
      <div v-if="rot === 0" class="invitation-font small">裏面をご確認ください / Please check the back side.</div>
      <div v-else class="invitation-font small">チケットを表示 / View Ticket.</div>
      <div>
        <button class="button-style" @click="onClick">
          <i class="material-icons info-icon">more_horiz</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'VipTemplate',
  props: {
    id: String,
    t: String,
    sid: String,
    colour1: String,
    colour2: String,
  },
  data() {
    return {
      info: {},
      isLoaded: false,
      isClicked: true,
      rot: 0,
    }
  },
  computed: {
    getDate() {
      if (this.info.day && this.info.time) {
        const textDate = this.info.day.split('_')[2].replace(/-/g, '/');
        let d = new Date(textDate)
        const t = this.info.time.replace('_', '~')
        return `${d.toLocaleDateString('ja', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' })} ${t}`
      }
      return ''
    }
  },
  methods: {
    closeInvite() {
      this.$emit('close')
    },
    generateQR() {
      const canvas = document.getElementById('canvas')
      // canvas.width = this.$refs.qr_container.clientWidth;
      // canvas.height = this.$refs.qr_container.clientHeight;
      QRCode.toCanvas(canvas, `${this.t}/${this.sid}`,
        {
          errorCorrectionLevel: 'H',
          version: 5,
          includeMargin: false,
          size: '100px'
        },
        function (error) {
          if (error) console.error(error)
        })
      this.isLoaded = true;
    },
    toggleClass(id, className) {
      const el = document.getElementById(id)
      el.classList.toggle(className)
    },
    onClick() {
      const content = document.getElementById("flip-card-inner");
      this.rot = this.rot === 0 ? 180 : 0;
      content.style = `transform: rotateY(${this.rot}deg); -webkit-transform:rotateY(${this.rot}deg); z-index: 100000;`;

    }
  },
  beforeMount() {
    this.info = this.$store.state.events[this.sid]
  },
  mounted() {
    this.generateQR();

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
[v-cloak] {
  display: none;
}

.invitation-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  /* max-width: 400px; */
  /* width: 300px; */
  /* background-color: rgb(92, 128, 25); */
  text-align: center;
  border-radius: 15px;
}

.qr-container {
  display: flex;
  margin: auto;
  align-items: center;
  height: 15vh;
  width: 15vh;
  max-height: 180px;
  max-width: 180px;

}

.button-style {
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  width: 26px;
  height: 26px;
  border-color: white;
  padding: 0px
}

.info-icon {
  border-radius: 100%;
  color: rgb(30, 30, 30);
}

.invitation-font {
  color: rgb(30, 30, 30);
  align-self: center;
  display: flex;
  align-items: center;
}

.name-font {
  color: rgb(30, 30, 30);
  /* font-size: x-large; */
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.entry-style {
  color: rgb(30, 30, 30);
  font-size: 12px;
  display: flex;
  gap: 10px
}

.conditions {
  color: rgb(30, 30, 30);
  font-size: 12px;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: 7px;
}

.logo {
  width: 100%;
  /* margin-bottom: 10px;
  margin-top: 20px; */
  height: 100%;
  min-width: 200px;
  min-height: 40px;
}

.header1 {
  font-size: 20px;
  color: white;
  /* background-color: rgb(106, 146, 32); */
  width: 100%;
  height: 10%;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
}

.header2 {
  display: flex;
  flex-direction: column;
  color: rgb(30, 30, 30);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 30px;
}

.header3 {
  display: flex;
  color: rgb(30, 30, 30);
  justify-content: flex-start;
  padding-top: 5px;
  border-top-style: solid;
  border-width: thin;
  margin-top: 5px;
  margin-right: auto;
}

.header4 {
  display: flex;
  color: rgb(30, 30, 30);
  justify-content: flex-start;
  margin-right: auto;
}

.qrcode {
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  height: 100% !important;
  width: 100% !important;

}

h3 {
  margin: 40px 0 0;
}



.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  justify-content: flex-end;
  align-items: center;
  /* max-width: 400px; */
  /* width: 300px; */
  /* background-color: rgb(92, 128, 25); */
  text-align: center;
  border-radius: 15px;

}

.flip-card-back .flip-card-front .flip-card-inner  {
    color: white;
    perspective: 0;
    -webkit-perspective: 0;
    -webkit-backface-visibility: hidden !important;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    visibility:visible;
    backface-visibility: hidden;
}

/* .rotate .flip-card-inner {
  transform: rotateY(180deg);
} */

.smaller {
  font-size: 0.677em;
  padding-left: 25px;
  padding-right: 25px;
  text-align: start !important;
}

.small {
  font-size: 0.76em;
}




/* .flip-card-front,
.flip-card-back { */
.flip-card-front {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

ul {
  list-style-type: disc;
}

.flip-card-back {
  /* background-color: rgb(106, 146, 32); */
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden;
  color: rgb(30, 30, 30);
  transform: rotateY(180deg);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  display: block;

}
</style>
