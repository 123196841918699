import { createRouter, createWebHistory } from 'vue-router'
// import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import VipInvitation from '../views/VipInvitation.vue';
// import Home from '../views/Home.vue';
import EventsPage from '../views/EventsPage'
import ConfirmRsvp from '../views/ConfirmRsvp'
import BlankPage from '../views/BlankPage'

const routes = [
  {
    path: '/',
    name: 'BlankPage',
    component: BlankPage,
    props: route => ({ id: route.query.id, t: route.query.t })
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: Login
  // },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    props: route => ({ id: route.query.id, t: route.query.t })
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: VipInvitation,
    props: route => ({ id: route.query.id, t: route.query.t, sid: route.query.sid })
  },
  {
    path: '/events',
    name: 'EventsPage',
    component: EventsPage,
    props: route => ({ id: route.query.id, t: route.query.t })
  },
  {
    path: '/rsvp',
    name: 'RSVP',
    component: ConfirmRsvp,
    props: route => ({ id: route.query.id, t: route.query.t, sid: route.query.sid })
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
