<template>
  <v-app>
    <Navbar />
    <v-main class="main">
      <router-view fluid class="elevation-0"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/NavBar";


export default {
  name: 'App',
  props: {

  },
  components: {
    Navbar
  },
  methods: {

  },
  beforeMount() {
  }
}
</script>

<style>
.main{
  display:flex;
  margin: auto;
  /* min-width: 350px; */
  width: 100%;
  justify-content: center;
}

</style>
