<template>
  <div>
    <v-row class="pa-4">
      <v-col class="event-col" v-for="item in $store.state.events" :key="item.sectionId">
        <v-card @click="showInvitation(item)" :color="item.colour1" class="justify-center text-center">
          <v-card-title class="font-grey"><v-img
              :src="require('@/assets/images/curation_fair_logo_black.png')"></v-img></v-card-title>
          <v-divider></v-divider>
          <v-card-title class="font-grey">
            <h2>{{ item.sectionName }}</h2>
          </v-card-title>
          <v-card-title class="font-grey"><a>{{ item.sectionDates }}</a></v-card-title>
          <v-divider></v-divider>
          <v-card-title class="font-grey">
            <p class="text-overline">ご招待者 / Invited By:</p>
            <p>{{ $store.state.rsvp.invitedBy }}</p>
        </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-btn size="large" :color="$store.state.events[item.id].registered ? 'primary' : 'warning'">
              {{ $store.state.events[item.id].registered ? 'チケット表示/Open' : '予約/Reserve' }}
            </v-btn>
          </v-card-text>
          
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="325px" scrollable>
      <div>
        <!-- <v-icon class="close-icon" @click="dialog = false" icon="mdi-close-circle-outline" size="x-large" color="rgb(224,224,224)"></v-icon> -->
        <VipTemplate v-if="$store.state.rsvp.t" :id="id" :t="t" :sid="sid" :colour1="$store.state.events[sid].colour1"
          :colour2="$store.state.events[sid].colour2" @close="dialog = false"/>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '@/plugins/firebase'
import VipTemplate from '@/components/VipTemplate.vue';



export default {
  name: 'EventsPage',
  components: {
    VipTemplate
  },
  props: {
    id: String,
    t: String
  },
  computed: {
    getImgUrl(path) {
      return require(path);
    },
   
  },
  data() {
    return {
      dialog: false,
      inv: {},
      loading: false,
    }
  },

  methods: {

    showInvitation(item) {
      if (item.registered) {
        this.dialog = true
        this.inv = item
        this.sid = `${item.id}`
        // this.$router.push(`/invitation?id=${this.id}&t=${this.t}&sid=${this.$store.state.rsvp.eventId}_${item.id}`)
      } else {
        if(this.$store.state.rsvp.eventId)
          this.$router.push(`/rsvp?id=${this.id}&t=${this.t}&sid=${this.$store.state.rsvp.eventId}_${item.id}`)
        else
          this.$router.push(`/events?id=${this.id}&t=${this.t}`)
          // this.$router
          // .push(`/events?id=${this.id}&t=${this.t}`)
          // .then(() => { this.$router.go() })
      } 
    }
  },
  mounted() {
  }

}
</script>

<style scoped>

.close-icon {
  position: relative;
  top: 18px;
  left: 93%;
  z-index: 10000;
}
/* .event-col{
  width: 100%
} */

@media all and (min-width: 761px){
  .event-col {
    max-width: 600px;
  }
}
.event {
  background-color: rgb(164, 84, 84);
  border-width: 1px;
  border-color: grey;
  border-radius: 5px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 5px;
  margin-right: 5px;
}

.button_container {
  display: flex;
  margin: auto;
  flex-direction: column;
  row-gap: 20px;
}

.button {
  background-color: grey;
}

.font-grey {
  color: rgb(30, 30, 30)
}


</style>
