<template>
  <div class="invitation-wrapper">
    <VipTemplate v-if="$store.state.rsvp.t" :id="id" 
                :t="t" :sid="sid.split('_')[1]" 
                :colour1="$store.state.events[sid.split('_')[1]].colour1" 
                :colour2="$store.state.events[sid.split('_')[1]].colour2"
    />
  </div>
</template>

<script>

import VipTemplate from '@/components/VipTemplate.vue';
// import { db } from '@/plugins/firebase'
// import { doc, getDoc } from "firebase/firestore";

export default {
  name: 'VipInvitation',
  components: {
    VipTemplate
  },
  props: {
      id: String,
      t: String,
      sid: String
  },
  data() {
    return {
      name: ''
    }
  },
  methods: {
    async getInfo() {

    },
  },
  beforeMount() {
    // if (this.$store.state.user) {
    //   // User already logged in, go to invitation
    //   this.getInfo()
    // }
  },
  mounted() {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.invitation-wrapper {
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  max-width: 350px;
  padding: 10px;
}

</style>
