<!-- Navbar.vue -->
<template>
  <v-app-bar app color="#40382E">
    <v-toolbar-title>
      <v-img :src="require('@/assets/logo.png')" max-width="250px" @click="goToEvents()"></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- <v-app-bar-nav-icon @click="toggleMenu"><v-btn icon="mdi-menu" color="white"></v-btn></v-app-bar-nav-icon> -->
    <!-- <v-menu offset-y="3" v-if="$store.state.rsvp.t && $store.state.rsvp.t === $route.query.t">
      <template v-slot:activator="{ props }">
        <v-btn icon="mdi-menu" v-bind="props"></v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in menuItemsLoggedOut" :key="index" @click="navigateTo(`/events?id=${this.$store.state.rsvp.id}&t=${this.$store.state.rsvp.t}`)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>

<script>

export default {
  data() {
    return {
      drawer: false,
      menu: true,
      menuItemsLoggedOut: [
        { text: 'Events', route: `/events?id=${this.$store.state.rsvp.id}&t=${this.$store.state.rsvp.t}` },
        // Add more menu items as needed
      ],
    };
  },
  computed: {

  },
  methods: {
    goToEvents(){
        this.$router.push(`/events?id=${this.$store.state.rsvp.id}&t=${this.$store.state.rsvp.t}`)
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    navigateTo(route) {
      this.$router.push(route);
      this.menu = false;
    },
  },
};
</script>
