<template>
  <v-card max-width="550px" class="register-card justify-center text-center mt-2 mb-2">
    <!-- <v-switch v-model="langEN" hide-details inset></v-switch> -->
    <v-row class="mt-2">
      <v-spacer />
      <v-switch inset v-model="langEN" :label="langEN ? '日本語' : 'English'"></v-switch>
    </v-row>
    <v-img :src="require('@/assets/images/curation_fair_logo_black.png')"></v-img>
    <v-divider></v-divider>
    <v-card-title class="justify-center text-center">Registration</v-card-title>
    <v-card-title>
      <p class="text-wrap">{{text[langEN ? 'en' : 'ja'].title1}}</p>
      <p class="text-wrap">{{text[langEN ? 'en' : 'ja'].title2}}</p>
    </v-card-title>
    <v-divider></v-divider>
    <v-form v-model="validated" ref="form" @submit.prevent="register" class="justify-center text-center mt-2" fast-fail>
      <v-card-text class="text-center justify-center">
        <v-text-field :label="text[langEN ? 'en' : 'ja'].firstname" v-model="firstname" :rules="textRules"></v-text-field>
        <v-text-field :label="text[langEN ? 'en' : 'ja'].lastname" v-model="lastname" :rules="textRules"></v-text-field>
        <v-text-field type="email" :label="text[langEN ? 'en' : 'ja'].email" v-model="email" :rules="emailRules"></v-text-field>
        <p>{{ text[langEN ? 'en' : 'ja'].conditions1 }}<a :href="text[langEN ? 'en' : 'ja'].href" target="_blank">{{ text[langEN ? 'en' : 'ja'].tnc }}</a>{{ text[langEN ? 'en' : 'ja'].conditions2 }}</p>
        <v-checkbox class="mt-2 mb-2 shrink d-inline-flex" density="compact" hide-details="true" :label="text[langEN ? 'en' : 'ja'].newsletter"
          v-model="newsletter"></v-checkbox>

      </v-card-text>

      <v-row class="justify-center text-center">

      </v-row>
      <v-row class="justify-center text-center">
        <v-btn :loading="loading" block size="x-large" color="#8A99A6" type="submit">{{ text[langEN ? 'en' : 'ja'].buttonreg }}</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>

import { db } from '@/plugins/firebase'
import { doc, setDoc, collection, serverTimestamp } from "firebase/firestore";

export default {
  name: 'RegisterUser',
  props: {
    id: String,
    t: String
  },
  components: {
  },
  data() {
    return {
      text: {
        'en': {
                title1: 'You are cordially invited to CURATION⇄FAIR Tokyo.',
                title2: 'Please register using the form below.',
                firstname: 'First name',
                lastname: 'Last name',
                email: 'E-mail address',
                conditions1: 'To complete your registration, your consent to the handling of ',
                tnc: 'personal information ',
                conditions2: 'is required.',
                buttonreg: 'Agree and register',
                newsletter: 'Receive notifications from the organizers.',
                href: 'https://curation-fair.com/en/privacy'
              },
        'ja': {
                title1: 'CURATION⇄FAIR Tokyoにご招待させていただきます。',
                title2: '下記のフォームからご登録をお願い致します。',
                firstname: '姓 (例：田中)',
                lastname: '名 (例：太郎)',
                email: 'メールアドレス',
                conditions1: 'ご登録には、',
                tnc: '個人情報',
                conditions2: 'の取扱いへの同意が必要です。',
                buttonreg: '同意して登録',
                newsletter: '事務局からのお知らせを受け取る',
                href: 'https://curation-fair.com/privacy'
              },

      },
      langEN: false,
      loading: false,
      newsletter: true,
      email: '',
      firstname: '',
      lastname: '',
      validated: false,
      emailRules: [
        v => !!v || 'Required',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      textRules: [
        v => !!v || 'Required',
      ],
    }
  },
  methods: {
    async register() {

      // e.preventDefault();
      if (this.validated) {
        try {
          const docRef = doc(collection(db, 'rsvp'), this.id)
          await setDoc(docRef, {
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            rsvp: true,
            token: this.t,
            newsletter: this.newsletter,
            ts: serverTimestamp()
          }, { merge: true })

          const rsvp = {
            id: this.id,
            firstname: this.firstname,
            lastname: this.lastname,
            invitedBy: '',
            day0: '',
            time0: '',
            day1: '',
            time1: '',
            plusone0: false,
            plusone1: false,
            t: this.t
          }
          this.$store.commit('initialiseStore', rsvp)
          this.$store.commit('setEvent', { id: 0, day: false, time: false, plusone: false, invitedBy: '' });
          this.$store.commit('setEvent', { id: 1, day: false, time: false, plusone: false, invitedBy: '' });
          this.loading = true

          setTimeout(() => {
            this.$store.commit('getConfirmation', {id: this.id, t: this.t})
            this.$router.push(`/events?id=${this.id}&t=${this.t}`)
            // location.reload();
            this.loading = false
          }, "5000");
        }
        catch (e) {
          console.log('error registering', e)
        }


      }
    },
  },
  mounted() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.rsvp.firstname)
        next(`/events?id=${this.id}&t=${this.t}`)
    })

  },
}
</script>

<style scoped>
.register-card {
  padding-bottom: 120px;
}
</style>
