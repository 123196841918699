import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from '@/routes/index'


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files

import { doc, getDoc, getDocs, collection, where, and, limit, query } from 'firebase/firestore';
import { db } from '@/plugins/firebase'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
})



// Create a new store instance.
const store = createStore({
  state() {
    return {
      user: undefined,
      uid: '',
      name: '',
      rsvp: {},
      events: [
        {
          id: 0,
          sectionId: '1705410652_0',
          colour1: "#8A99A6",
          colour2: "#40382E",
          eventName: "Curation Fair 2024",
          sectionName: "Exhibition",
          // sectionDates: "(2月21~3月3日開催)",
          sectionDates: "2024. 2.21 (wed) - 3.3 (sun)",
          bottomLine: "チケット予約",
          logoUrl: "@/assets/images/curation_fair_logo_black.png",
          registered: false,
        },
        {
          id: 1,
          sectionId: '1705410652_1',
          colour1: "#C2BD90",
          colour2: "#26211F",
          eventName: "Curation Fair 2024",
          sectionName: "Art Fair",
          // sectionDates: "(3月8日~3月11日開催)",
          sectionDates: "2024. 3.8 (fri) - 3.11 (mon)",
          bottomLine: "チケット予約",
          logoUrl: "@/assets/images/curation_fair_logo_black.png",
          registered: false,
        }
      ]

    }
  },
  mutations: {
    setRsvp(state, rsvp) {
      state.rsvp = rsvp;
      state.events[0].registered = !!rsvp.day0 && !!rsvp.time0
      state.events[1].registered = !!rsvp.day1 && !!rsvp.time1
      // state.firstname = details.firstname
      // state.lastname = details.lastname
      // state.invitedBy = details.invitedBy
      // state.day0 = details.day0
      // state.time1 = details.time1
      // state.day1 = details.day1
      // state.time1 = details.time1
      // state.token = details.token
    },
    initialiseStore(state, rsvp){
      state.rsvp = rsvp;
      state.events[0].registered = false;
      state.events[1].registered = false;
    },
    setEvent(state, event) {
      // sectionId: `${item.eventId}_0`, day: item.day0, time: item.time0, plusone: item.plusone0, invitedBy: item.invitedBy, registered: !!item.day0 && !!item.time0
      state.events[event.id].day = event.day
      state.events[event.id].time = event.time
      state.events[event.id].plusone = event.plusone
      state.events[event.id].registered = event.registered
    },
    async getConfirmation(state, info) {
      const tDoc = await getDocs(query(collection(db, `tokens`), and(where("id", "==", info.id), where("t", "==", info.t)), limit(1)))
      if (!tDoc.size > 0)
        return
    
      const confDoc = await getDoc(doc(db, `confirmed/${info.id}`))
      if (confDoc.exists()) {
        const item = confDoc.data()
        const rsvp = {
          id: info.id,
          firstname: item.firstname,
          lastname: item.lastname,
          invitedBy: item.entity,
          day0: item.day0,
          time0: item.time0,
          day1: item.day1,
          time1: item.time1,
          plusone0: item.plusone0,
          plusone1: item.plusone1,
          t: info.t,
          eventId: item.eventId
        }
        state.rsvp = rsvp;
        
        state.events[0].day = item.day0
        state.events[0].time = item.time0
        state.events[0].plusone = item.plusone0
        state.events[0].registered = !!item.day0 && !!item.time0

        state.events[1].day = item.day1
        state.events[1].time = item.time1
        state.events[1].plusone = item.plusone1
        state.events[1].registered = !!item.day1 && !!item.time1
        // state.events[0].registered = !!rsvp.day0 && !!rsvp.time0
        // state.events[1].registered = !!rsvp.day1 && !!rsvp.time1
        // store.commit('setEvent', { id: 0, sectionId: `${item.eventId}_0`, day: item.day0, time: item.time0, plusone: item.plusone0, registered: !!item.day0 && !!item.time0 });
        // store.commit('setEvent', { id: 1, sectionId: `${item.eventId}_1`, day: item.day1, time: item.time1, plusone: item.plusone1, registered: !!item.day1 && !!item.time1 });

        // state.events[0] = { ...state.events[0],  sectionId: `${item.eventId}_0`, day: item.day0, time: item.time0, plusone: item.plusone0, registered: !!item.day0 && !!item.time0};
        // state.events[1] = { ...state.events[1],  sectionId: `${item.eventId}_1`, day: item.day1, time: item.time1, plusone: item.plusone1, registered: !!item.day1 && !!item.time1};
        
        // if (from.path !== '/events')
        //   return `/events?id=${id}&t=${t}`
      } 
    }
  },
  
})

router.beforeResolve(async (to, from, next) => {
  const t = to.query.t
  const id = to.query.id
  let ok = store.state.rsvp.t === t
  let rsvp = {}
  if (!ok) {
    const tDoc = await getDocs(query(collection(db, `tokens`), and(where("id", "==", id), where("t", "==", t)), limit(1)))
    if (!tDoc.size > 0)
      next(false)
  
    const confDoc = await getDoc(doc(db, `confirmed/${id}`))
    if (confDoc.exists()) {
      const item = confDoc.data()
      rsvp = {
        id: id,
        firstname: item.firstname,
        lastname: item.lastname,
        invitedBy: item.entity,
        day0: item.day0,
        time0: item.time0,
        day1: item.day1,
        time1: item.time1,
        plusone0: item.plusone0,
        plusone1: item.plusone1,
        t: t,
        eventId: item.eventId
      }
      store.commit('setRsvp', rsvp);
      store.commit('setEvent', { id: 0, sectionId: `${item.eventId}_0`, day: item.day0, time: item.time0, plusone: item.plusone0, registered: !!item.day0 && !!item.time0 });
      store.commit('setEvent', { id: 1, sectionId: `${item.eventId}_1`, day: item.day1, time: item.time1, plusone: item.plusone1, registered: !!item.day1 && !!item.time1 });
      // if (from.path !== '/events')
      //   return `/events?id=${id}&t=${t}`
      ok = true
      
    } 
    
  }
  if (ok) {
    if (to.path.toLowerCase() === '/register')
      next(`/events?id=${id}&t=${t}`)
    else if (to.path.toLocaleLowerCase() === '/rsvp') {
      if (to.query.sid) {
        if (store.state.events[to.query.sid.split('_')[1]].registered)
          next(`/invitation?id=${id}&t=${t}&sid=${to.query.sid}`)
        else
          next()
      } else {
        next(false)
      }
    } else if (to.path.toLocaleLowerCase() === '/invitation') {
      if (to.query.sid) {
        if (store.state[to.query.sid.split('_')[1]].registered) {
          next()
        }
        else {
          next(`/rsvp?id=${id}&t=${t}&sid=${to.query.sid}`)
        }
      } else {
        next(false)
      }
    } else {
      next()
    }
  } else {
    // next(`/register?id=${id}&t=${t}`)
    if (to.path.toLowerCase() === '/register')
      next()
    else
      next(`/register?id=${id}&t=${t}`)
  }

});


createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')

// .use(store)


